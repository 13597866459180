import React from 'react';
import Preloader from './components/Preloader';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import GetStarted from './components/GetStarted';
import Header from './components/Header';
import Question from './components/Question';
import Scene from './components/Scene';
import Result from './components/Result';
import ResultMobile from './components/ResultMobile';

import { AppWrapper, AppHeader, AppChrome, AppScene } from './App.styles';

const App = ({
  currentQuestionId,
  hasQuizStarted,
  preselectedAnswerId,
  showResult,
  deviceType
}) => {
  const isMobile =
    deviceType === 'small_portrait' || deviceType === 'small_landscape';

  return (
    <Preloader>
      <AppWrapper>
        <AppHeader>
          <Header showQuiz={hasQuizStarted || showResult} />
        </AppHeader>
        <AppChrome>
          {!hasQuizStarted && !showResult && <GetStarted />}
          {hasQuizStarted && !showResult && <Question />}
          {showResult && (isMobile ? <ResultMobile /> : <Result />)}
        </AppChrome>
        <AppScene>
          {(hasQuizStarted || showResult) && (
            <Scene
              id={currentQuestionId + 1}
              previewId={preselectedAnswerId}
              result={showResult}
            />
          )}
        </AppScene>
      </AppWrapper>
    </Preloader>
  );
};

App.propTypes = {
  currentQuestionId: PropTypes.number.isRequired,
  hasQuizStarted: PropTypes.bool.isRequired,
  preselectedAnswerId: PropTypes.number,
  showResult: PropTypes.bool.isRequired,
  deviceType: PropTypes.string.isRequired
};

App.defaultProps = {
  currentQuestionId: null,
  hasQuizStarted: false,
  preselectedAnswerId: undefined,
  showResult: false,
  deviceType: undefined
};

const mapStateToProps = ({
  currentQuestionId,
  hasQuizStarted,
  showResult,
  preselectedAnswerId,
  deviceType
}) => ({
  currentQuestionId,
  hasQuizStarted,
  showResult,
  preselectedAnswerId,
  deviceType
});

export default connect(mapStateToProps)(App);
