// - de 8 a 10 encerts
// - de 5 a 7 encerts
// - menys de 5 encerts

const questions = [
  {
    id: 0,
    question:
      'Si has de travessar un vessant perillós a l’hivern, què has de fer per evitar que t’atrapi una allau?',
    answer:
      'Si has de travessar un vessant perillós a l’hivern, el més segur és que ho facis per la part més alta possible i que evitis travessar-la horitzontalment. Així tindràs menys possibilitats de fer caure una allau i, si ho fas, serà menys probable que et colgui.',
    answerOptions: [
      {
        id: 0,
        answer:
          'Travessar-la per la part del mig i evitar fer-ho horitzontalment.',
        valid: false
      },
      {
        id: 1,
        answer:
          'Travessar-la per la part més alta possible i evitar fer-ho horitzontalment.',
        valid: true
      },
      {
        id: 2,
        answer:
          'Travessar-la per qualsevol part, per fer-ho el més de pressa possible.',
        valid: false
      }
    ]
  },
  {
    id: 1,
    question:
      'Si circules per una zona amb senyalització de perill per despreniments, quan has d’extremar les precaucions?',
    answer:
      'L’aigua pot arrossegar roques i terra a la carretera o fer que caiguin quan estàs passant, per això és important que vagis amb molt de compte quan plou i passes per aquestes zones.',
    answerOptions: [
      {
        id: 0,
        answer: 'Quan fa molt sol.',
        valid: false
      },
      {
        id: 1,
        answer: 'De nit.',
        valid: false
      },
      {
        id: 2,
        answer: 'Quan plou.',
        valid: true
      }
    ]
  },
  {
    id: 2,
    question: 'Si tens cotxe, què cal que tingui el teu catakit?	',
    answer:
      'El catakit o motxilla d’emergència és una bossa que conté objectes essencials que pots necessitar en cas que es produís una emergència important. Els llumins i espelmes en una caixa o bossa hermètica són útils tant per escalfar-nos com per escalfar una beguda o fer llum.',
    answerOptions: [
      {
        id: 0,
        answer: 'Llumins i espelmes en una caixa o bossa hermètica.',
        valid: true
      },
      {
        id: 1,
        answer: 'Un telèfon mòbil.',
        valid: false
      },
      {
        id: 2,
        answer: 'Crampons.',
        valid: false
      }
    ]
  },
  {
    id: 3,
    question: 'En cas de fortes ventades…',
    answer:
      'En cas que hi hagi fortes ventades allunya’t dels objectes que puguin caure. Si vas en cotxe, limita la velocitat i extrema les precaucions, poden haver- hi obstacles a la via. El més segur és que et quedis a l’interior d’un edifici amb portes, finestres i tendals tancats i abaixis completament les persianes.Treu dels balcons i terrasses els objectes que puguin caure o sortir volant.',
    answerOptions: [
      {
        id: 0,
        answer: 'puja a un lloc ben alt.',
        valid: false
      },
      {
        id: 1,
        answer: "queda't a l'interior i tanca portes i finestres.",
        valid: true
      },
      {
        id: 2,
        answer: 'aparca el cotxe sota un abre i camina.',
        valid: false
      }
    ]
  },
  {
    id: 4,
    question: 'Els dies de revetlla…',
    answer:
      'Per Sant Joan i altres dies en què es llencen petards o coets, has de tancar les portes i finestres i recollir la roba estesa i els tendals per evitar incendis.',
    answerOptions: [
      {
        id: 0,
        answer: 's’han d’encendre les fogueres a prop d’envans fluvials.',
        valid: false
      },
      {
        id: 1,
        answer: 'cal tancar les portes i finestres.',
        valid: true
      },
      {
        id: 2,
        answer: 'no cal comunicar al Comú que s’encendrà una foguera.',
        valid: false
      }
    ]
  },
  {
    id: 5,
    question: 'Què has de fer si ets a casa durant un sisme?',
    answer:
      'En cas de sisme, és més segur quedar-se dins de casa o d’algun edifici i, a ser possible, posar-se sota d’un moble sòlid que et protegeixi dels objectes que puguin caure o al costat d’una paret mestra.',
    answerOptions: [
      {
        id: 0,
        answer: 'Posar-te al costat d’un gran prestatge.',
        valid: false
      },
      {
        id: 1,
        answer: 'Sortir ràpidament de l’edifici.',
        valid: false
      },
      {
        id: 2,
        answer: 'Posar-te sota d’una taula o en un angle de paret.',
        valid: true
      }
    ]
  },
  {
    id: 6,
    question: 'Quines precaucions has de prendre a la muntanya?',
    answer:
      'Avisar a algú sobre la teva ruta, tenir localitzats els refugis, portar aigua i aliments i haver consultat la previsió del temps són algunes de les precaucions més importants que has de prendre, així com portar un telèfon mòbil per a qualsevol emergència amb l’app Safe365 activada.',
    answerOptions: [
      {
        id: 0,
        answer:
          'Avisar a algú sobre la teva ruta, caminar a poc a poc, portar aigua i aliments i haver consultat la previsió del temps.',
        valid: false
      },
      {
        id: 1,
        answer:
          'Avisar a algú sobre la teva ruta, portar aigua i aliments, haver consultat la previsió del temps i no plegar bolets.',
        valid: false
      },
      {
        id: 2,
        answer:
          'Avisar a algú sobre la teva ruta, tenir localitzats els refugis, portar aigua i aliments i haver consultat la previsió del temps.',
        valid: true
      }
    ]
  },
  {
    id: 7,
    question:
      'Quin espai és segur per refugiar-se si hi ha algun tipus de fuita contaminant, inflamable o explosiva a la zona?',
    answer:
      "Els vehicles no són bons refugis en aquestes situacions. Si t'hi trobes, has d'intentar quedar-te dins d'un edifici, tncar portes i finestres i abaixar les persianes.",
    answerOptions: [
      {
        id: 0,
        answer: 'Un cotxe',
        valid: false
      },
      {
        id: 1,
        answer: 'Un edifici',
        valid: true
      },
      {
        id: 2,
        answer: 'Un cotxe o edifici',
        valid: false
      }
    ]
  },
  {
    id: 8,
    question:
      'Quina és l’alçada màxima que poden tenir les flames d’una barbacoa o d’un foc de camp per a la teva seguretat?',
    answer:
      'Per evitar accidents i perills, les flames d’aquest tipus de focs no han de superar el metre d’alçada. Mentre que les flames de cremes agrícoles o forestals no han de sobrepassar els 3 metres d’alçada.',
    answerOptions: [
      {
        id: 0,
        answer: '1 metre',
        valid: true
      },
      {
        id: 1,
        answer: '2 metres',
        valid: false
      },
      {
        id: 2,
        answer: '3 metres',
        valid: false
      }
    ]
  },
  {
    id: 9,
    question: 'Alguns símptomes dels cops de calor són...',
    answer:
      'L’atordiment, les nàusees, la incoherència en la parla, les rampes musculars, el vertigen, el mal de cap i la pèrdua del coneixement, entre altres, poden ser símptomes d’un cop de calor. Si el pateixes o trobes algú amb els seus símptomes, has de trucar al 112 immediatament.',
    answerOptions: [
      {
        id: 0,
        answer: 'L’atordiment, les nàusees i el mal humor.',
        valid: false
      },
      {
        id: 1,
        answer: 'L’atordiment, les nàusees i la incoherència en la parla.',
        valid: true
      },
      {
        id: 2,
        answer: 'L’atordiment, les nàusees i la fam.',
        valid: false
      }
    ]
  }
];

export default questions;
