import React from 'react';
import { connect } from 'react-redux';
import { startQuiz } from '../../redux/actions';
import Button from '../Button';
import Vallineu from '../Vallineu';
import cloudsSVG from './clouds.svg';
import cloudsSmallSVG from './clouds-small.svg';
import quizSVG from './quiz.svg';
import {
  GetStartedCanvas,
  GetStartedContainer,
  GetStartedClouds,
  GetStartedQuiz,
  GetStartedPosedVallineu,
  GetStartedRegularP,
  GetStartedBoldP,
  GetStartedIntro
} from './GetStated.styles';

class GetStarted extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pose: 'moveIn'
    };
  }

  componentDidMount() {
    setTimeout(() => this.setState({ pose: 'moveOut' }), 1500);
  }

  render() {
    const { startQuiz, deviceType } = this.props;
    const isMobile =
      deviceType === 'small_portrait' || deviceType === 'small_landscape';

    return (
      <section>
        <GetStartedCanvas>
          <GetStartedContainer>
            <GetStartedClouds
              initialPose={isMobile ? 'initMobile' : 'init'}
              pose={isMobile ? 'moveInMobile' : 'moveIn'}
              src={isMobile ? cloudsSmallSVG : cloudsSVG}
              alt=""
            />
          </GetStartedContainer>
          <GetStartedContainer>
            <GetStartedQuiz
              initialPose="init"
              pose="moveIn"
              src={quizSVG}
              alt="Quiz: Quant saps sobre l'autoprotecció?"
            />
          </GetStartedContainer>
          <GetStartedContainer>
            <GetStartedPosedVallineu
              initialPose={isMobile ? 'initMobile' : 'init'}
              pose={`${this.state.pose}${isMobile ? 'Mobile' : ''}`}
            >
              <Vallineu />
            </GetStartedPosedVallineu>
          </GetStartedContainer>
          <GetStartedContainer>
            <GetStartedIntro initialPose="init" pose="moveIn">
              <GetStartedRegularP>
                Estar preparat per a qualsevol emergència i ser capaç
                d’evitar-les és molt important.
              </GetStartedRegularP>
              <GetStartedBoldP>
                Vols saber si tens els coneixements necessaris?
              </GetStartedBoldP>
              <GetStartedRegularP>
                <Button onClick={startQuiz}>Sí!</Button>
              </GetStartedRegularP>
            </GetStartedIntro>
          </GetStartedContainer>
        </GetStartedCanvas>
      </section>
    );
  }
}

const mapStateToProps = ({ deviceType }) => ({
  deviceType
});

export { cloudsSVG };
export { quizSVG };
export default connect(
  mapStateToProps,
  { startQuiz }
)(GetStarted);
