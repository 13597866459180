import { css, createGlobalStyle } from 'styled-components/macro';

/**
 * Colors
 */

export const color = {
  blue: '#28337c',
  gray: '#6b6c6b',
  green: '#82a71d',
  lightBlue: '#6aa3ba',
  lighterBlue: '#b4d1dd',
  red: '#f05a28',
  lightRed: '#ff7f58',
  white: '#fff'
};

/**
 * Media Queries
 */

export const sizes = {
  s: 320,
  m: 768,
  l: 1024,
  xl: 1600
};

export const breakpoints = {
  small_portrait: {
    maxDeviceWidth: sizes.m - 1,
    orientation: 'portrait'
  },

  small_landscape: {
    maxDeviceWidth: sizes.l - 1,
    orientation: 'landscape',
    message: 'Gira el mòbil en posició vertical per jugar al quiz'
  },

  medium: {
    minDeviceWidth: sizes.l,
    maxWidth: sizes.xl - 1
  },

  large: {
    minDeviceWidth: sizes.xl
  }
};

const mapKeysToMediaString = {
  minWidth: 'min-width',
  maxWidth: 'max-width',
  minDeviceWidth: 'min-device-width',
  maxDeviceWidth: 'max-device-width',
  orientation: 'orientation'
};

export const media = Object.keys(breakpoints).reduce((acc, label) => {
  // Construct the media quary feature expressions from the brakpoint object
  // For each expression it maps the key to the proper keyword (e.g. minWidth -> 'min-width')
  // and transforms numeric values into pixels (e.g. 1024 -> '1024px')
  const mediaFeatures = Object.entries(breakpoints[label]).map(
    ([key, value]) =>
      `(${mapKeysToMediaString[key]}: ${
        typeof value === 'number' ? `${value}px` : value
      })`
  );

  const mediaQuery = `@media ${mediaFeatures.join(' and ')}`;

  acc[label] = (...args) => css`
    ${mediaQuery} {
      ${css(...args)}
    }
  `;

  return acc;
}, {});

/**
 * Font sizes
 */

export const fontSizes = {
  tiny: { default: 14, xl: 19 },
  small: { default: 16, xl: 23 },
  medium: { default: 19, xl: 28 },
  large: { default: 21, xl: 30 },
  extra: { default: 26, xl: 40 }
};

export const fontSize = Object.keys(fontSizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    font-size: ${fontSizes[label].default}px;

    @media (min-device-width: 1600px) {
      font-size: ${fontSizes[label].xl}px;
    }
  `;

  return acc;
}, {});

export const viewportHeight = css`
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
`;

export const GlobalStyle = createGlobalStyle`
  body,
  html {
    width: 100vw;
    ${viewportHeight};
    overflow: hidden;
    background-color: #6aa3ba;
  }

  body {
    font-family: 'Roboto Condensed', 'Helvetica Neue', 'Arial', sans-serif;
  }
  
  *, *::before, *::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  p {
    font-weight: 700;
  }
`;
