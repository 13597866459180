import React from 'react';
import styled from 'styled-components/macro';
import { connect } from 'react-redux';
import questions from '../../questions';
import QuestionNumber from '../QuestionNumber';
import QuestionTitle from '../QuestionTitle';
import QuestionExplanation from '../QuestionExplanation';
import QuestionOptions from '../QuestionOptions';
import { QuestionWrapper } from './Question.style';
import {
  selectAnswer,
  markSuccesfulAnswer,
  markIncorrectAnswer,
  preselectAnswer,
  unselectAnswer
} from '../../redux/actions';

const Question = ({ currentQuestionId, isCorrectAnswerSelected }) => {
  const currentQuestion = questions[currentQuestionId];
  const { question, answer, answerOptions } = currentQuestion;
  const validAnswerId = currentQuestion.answerOptions.find((a) => a.valid).id;

  return (
    <QuestionWrapper key={currentQuestionId}>
      <QuestionNumber />
      <QuestionTitle title={question} />
      <QuestionExplanation
        success={isCorrectAnswerSelected}
        explanation={answer}
      />
      <QuestionOptions options={answerOptions} validAnswerId={validAnswerId} />
    </QuestionWrapper>
  );
};

const mapStateToProps = ({ currentQuestionId, isCorrectAnswerSelected }) => ({
  currentQuestionId,
  isCorrectAnswerSelected
});

const mapDispatchToProps = {
  selectAnswer,
  markSuccesfulAnswer,
  markIncorrectAnswer,
  preselectAnswer,
  unselectAnswer
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Question);
