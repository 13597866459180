import React from 'react';
import styled from 'styled-components';
import { color, fontSize } from '../../css';

const StyledTitle = styled.div`
  z-index: 0;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: ${color.blue};
  padding: 10px 10px 10px 60px;
  min-height: 82px;

  /* landscape
    padding: 10px 120px 10px 86px;
  */

  @media (min-device-width: 768px) {
    width: 600px;
    max-width: 100%;
    padding: 15px 40px;
    border-radius: 30px;
  }

  @media (min-device-width: 1600px) {
    width: 864px;
    padding: 20px 80px;
    border-radius: 40px;
  }
`;

const TitleText = styled.h1`
  font-size: 16px;
  color: ${color.white};
  width: 100%;

  @media (min-device-width: 768px) {
    ${fontSize.large}
    text-align: center;
  }
`;

const QuestionTitle = ({ title }) => (
  <StyledTitle>
    <TitleText>{title}</TitleText>
  </StyledTitle>
);

export default QuestionTitle;
