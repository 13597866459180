import React from 'react';
import Preload from 'react-preload';
import { quizSVG, cloudsSVG } from '../GetStarted';
import { scenes, result } from '../Scene';
import PreloadingScreen from '../PreloaderScreen';

const preloadedImages = scenes.reduce(
  (acc, scene) => {
    Object.entries(scene).forEach((entry) => acc.push(entry[1]));

    return acc;
  },
  [result, quizSVG, cloudsSVG]
);

const Preloader = ({ children }) => (
  <Preload
    loadingIndicator={<PreloadingScreen />}
    images={preloadedImages}
    autoResolveDelay={10000}
    onError={(err) => console.error(err)}
    resolveOnError={true}
    mountChildren={true}
  >
    {children}
  </Preload>
);

export default Preloader;
