import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import ScreenResolutionHandler from './components/ScreenResolutionHandler';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { GlobalStyle } from './css';
import { Provider } from 'react-redux';
import store from './redux/store';

let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);

window.addEventListener('resize', () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});

ReactDOM.render(
  <Provider store={store}>
    <GlobalStyle />
    <ScreenResolutionHandler>
      <App />
    </ScreenResolutionHandler>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
