import {
  START_QUIZ,
  END_QUIZ,
  INCREMENT_COUNTER,
  NEXT_QUESTION,
  SELECT_ANSWER,
  PRESELECT_ANSWER,
  UNSELECT_ANSWER,
  MARK_SUCCESFUL_ANSWER,
  MARK_INCORRECT_ANSWER,
  UPDATE_QUESTION_COUNT,
  SET_DEVICE_TYPE
} from './actionTypes';

export const startQuiz = () => ({
  type: START_QUIZ
});

export const endQuiz = () => ({
  type: END_QUIZ
});

export const incrementCounter = () => ({
  type: INCREMENT_COUNTER
});

export const nextQuestion = () => {
  return {
    type: NEXT_QUESTION
  };
};

export const selectAnswer = (id) => ({
  type: SELECT_ANSWER,
  payload: {
    id: id
  }
});

export const preselectAnswer = (id) => ({
  type: PRESELECT_ANSWER,
  payload: {
    id: id
  }
});

export const unselectAnswer = (id) => ({
  type: UNSELECT_ANSWER,
  payload: {
    id: id
  }
});

export const markSuccesfulAnswer = () => ({
  type: MARK_SUCCESFUL_ANSWER
});

export const markIncorrectAnswer = () => ({
  type: MARK_INCORRECT_ANSWER
});

export const updateQuestionCount = (count) => ({
  type: UPDATE_QUESTION_COUNT,
  payload: {
    count: count
  }
});

export const setDeviceType = (type) => ({
  type: SET_DEVICE_TYPE,
  payload: type
});
