import posed from 'react-pose';
import styled from 'styled-components/macro';
import { color, fontSize, viewportHeight } from '../../css';

export const GetStartedCanvas = styled.div`
  position: relative;
  width: 100%;
  ${viewportHeight};
`;

export const GetStartedContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const cloudsPoses = {
  init: { scale: 0.25, opacity: 0 },
  moveIn: { scale: 1, opacity: 1, delay: 750 },
  initMobile: { scale: 0.25, opacity: 0 },
  moveInMobile: { scale: 1.33, opacity: 1, delay: 750 }
};

export const GetStartedClouds = styled(posed.img(cloudsPoses))`
  width: 100%;
  height: auto;
  margin-top: -90px;

  @media (min-device-width: 768px) {
    width: 100%;
    height: auto;
    max-width: 860px;
    margin-top: -90px;
    margin-left: -90px;
  }

  @media (min-device-width: 1600px) {
    max-width: 1260px;
    margin-top: -70px;
    margin-left: -120px;
  }
`;

const quizPoses = {
  init: { top: 100, scale: 0.95, opacity: 0 },
  moveIn: { flip: true, top: 0, scale: 1, opacity: 1, delay: 1000 }
};

export const GetStartedQuiz = styled(posed.img(quizPoses))`
  width: 100%;
  max-width: 85%;
  margin: -260px 90px 0;

  @media (min-device-width: 768px) {
    max-width: 460px;
    margin-top: -260px;
  }

  @media (min-device-width: 1600px) {
    max-width: 680px;
    margin-top: -280px;
  }
`;

const posedVallineuPoses = {
  init: { top: 400, scale: 0.95 },
  initMobile: { top: 400, scale: 0.95 },
  moveIn: {
    flip: true,
    top: '42vh',
    scale: 1,
    transition: {
      type: 'spring',
      stiffness: 100,
      damping: 10
    }
  },
  moveOut: {
    scale: 0.75,
    opacity: 0
  },
  moveInMobile: {
    flip: true,
    top: '42vh',
    scale: 1,
    transition: {
      type: 'spring',
      stiffness: 100,
      damping: 10
    }
  },
  moveOutMobile: {
    scale: 0.75,
    opacity: 0
  }
};

export const GetStartedPosedVallineu = styled(posed.div(posedVallineuPoses))`
  position: absolute;
  margin-top: -40px;

  @media (min-device-width: 1600px) {
    margin-top: 80px;
  }
`;

export const GetStartedRegularP = styled.p`
  color: ${color.blue};
  font-weight: 500;
  ${fontSize.medium}
  max-width: 320px;
  text-align: center;
  margin-bottom: 10px;

  @media (min-device-width: 1600px) {
    max-width: 480px;
    margin-bottom: 20px;
  }
`;

export const GetStartedBoldP = styled(GetStartedRegularP)`
  font-weight: 700;
`;

export const GetStartedIntro = styled(
  posed.div({
    init: { opacity: 0, scale: 0.5 },
    moveIn: { opacity: 1, scale: 1, delay: 1900 }
  })
)`
  margin-top: 60px;

  @media (min-device-width: 1600px) {
    margin-top: 180px;
  }
`;
