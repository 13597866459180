import React from 'react';
import Spinner from 'react-spinkit';
import { PreloadingScreenWrapper } from './PreloaderScreen.styles';

const PreloadingScreen = () => (
  <PreloadingScreenWrapper>
    <Spinner name="ball-scale-ripple" color="white" />
  </PreloadingScreenWrapper>
);

export default PreloadingScreen;
