import styled from 'styled-components/macro';
import { color } from '../../css';

export const HeaderWrapper = styled.header`
  position: relative;
  display: flex;
  align-items: flex-start;
  width: 100%;

  @media (min-device-width: 768px) {
    padding: 22px 35px 0;

    &:before {
      content: '';
      position: absolute;
      z-index: -1;
      left: 0;
      top: 0;
      display: block;
      width: 100%;
      height: calc(48px + 60px);
      background-color: ${color.lightBlue};

      @media (min-device-width: 1600px) {
        height: calc(48px + 80px);
      }
    }
  }
`;

export const HeaderLogo = styled.img`
  display: none;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
  width: 80px;
  height: auto;

  @media (min-device-width: 768px) {
    display: block;
    position: relative;
    width: 115px;
    top: 0;
    right: 0;
  }

  @media (min-device-width: 1600px) {
    width: 152px;
  }
`;

export const HeaderQuiz = styled.img`
  display: none;

  @media (min-device-width: 768px) {
    display: block;
  }

  width: 225px;
  height: auto;
  margin-left: 15px;
  margin-top: 10px;

  @media (min-device-width: 1600px) {
    width: 300px;
    margin-left: 30px;
  }
`;

export const HeaderGovern = styled.img`
  display: none;

  @media (min-device-width: 768px) {
    display: block;
    width: 115px;
    height: auto;
  }

  @media (min-device-width: 1600px) {
    width: 156px;
  }
`;

export const HeaderSpacer = styled.div`
  flex: 1;
`;
