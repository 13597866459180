import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { color, fontSize } from '../../css';

const StyledQuestionNumber = styled.div`
  position: absolute;
  top: 0;
  width: 40px;
  height: 40px;
  padding: 8px;
  font-weight: 900;
  ${fontSize.large}
  color: ${color.blue};
  text-align: center;
  border-radius: 100%;
  background-color: ${color.red};
  z-index: 2;
  margin-left: 10px;
  margin-top: 20px;

  /* Landscape
width: 100px;
  height: 84px;
  margin-left: -28px;
  padding: 32px 15px 32px 36px;
  border-radius: 0 60px 60px 0;
  */

  @media (min-device-width: 768px) {
    z-index: 0;
    width: 100px;
    height: 100px;
    padding: 15px 0 0 0;
    margin-top: 40px;
    margin-left: 0;
    border-radius: 100%;
    ${fontSize.small}
  }

  @media (min-device-width: 1600px) {
    width: 75px;
    height: 75px;
    margin-top: 56px;
 }
`;

const QuestionCount = styled.span`
  display: none;

  @media (min-device-width: 768px) {
    display: inline-block;
  }
`;

const QuestionNumber = ({ currentQuestionId, questionCount }) => (
  <StyledQuestionNumber key={currentQuestionId}>
    {(currentQuestionId + 1).toLocaleString(undefined, {
      minimumIntegerDigits: 2
    })}
    <QuestionCount>/{questionCount}</QuestionCount>
  </StyledQuestionNumber>
);

const mapStateToProps = ({ currentQuestionId, questionCount }) => ({
  currentQuestionId,
  questionCount
});

export default connect(mapStateToProps)(QuestionNumber);
