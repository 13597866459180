import React from 'react';
import MediaQuery from 'react-responsive';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { setDeviceType } from '../../redux/actions';
import { color, breakpoints, viewportHeight } from '../../css';

const Message = styled.div`
  color: ${color.white};
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  ${viewportHeight};
`;

const ScreenResolutionHandler = ({ children, setDeviceType }) => {
  return (
    <>
      {Object.entries(breakpoints).map(([key, conditions], i) => (
        <MediaQuery key={i} {...conditions}>
          {(matches) => {
            if (matches) {
              setDeviceType({ type: key });

              if (conditions.message) {
                return <Message>{conditions.message}</Message>;
              }

              return children;
            } else {
              return null;
            }
          }}
        </MediaQuery>
      ))}
    </>
  );
};

const mapDispatchToProps = {
  setDeviceType
};

export default connect(
  null,
  mapDispatchToProps
)(ScreenResolutionHandler);
