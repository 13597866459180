export const START_QUIZ = 'START_QUIZ';
export const END_QUIZ = 'END_QUIZ';
export const UPDATE_QUESTION_COUNT = 'UPDATE_QUESTION_COUNT';
export const INCREMENT_COUNTER = 'INCREMENT_COUNTER';
export const NEXT_QUESTION = 'NEXT_QUESTION';
export const SELECT_ANSWER = 'SELECT_ANSWER';
export const PRESELECT_ANSWER = 'PRESELECT_ANSWER';
export const UNSELECT_ANSWER = 'UNSELECT_ANSWER';
export const MARK_SUCCESFUL_ANSWER = 'MARK_SUCCESFUL_ANSWER';
export const MARK_INCORRECT_ANSWER = 'MARK_INCORRECT_ANSWER';
export const SET_DEVICE_TYPE = 'SET_DEVICE_TYPE';
