import styled from 'styled-components/macro';

export const AppWrapper = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

export const AppHeader = styled.div`
  position: relative;
  width: 100%;
  z-index: 3;

  @media (min-device-width: 768px) {
    position: absolute;
    z-index: 1;
  }
`;

export const AppChrome = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;

  z-index: 2;

  @media (min-device-width: 768px) {
  }
`;

export const AppScene = styled.div`
  position: absolute;
  top: 40px;
  width: 100%;
  height: calc(100% - 82px - 180px);
  z-index: 0;

  @media (min-device-width: 768px) {
    top: 0;
    height: 100%;
  }
`;
