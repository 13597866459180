import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';
import { color, fontSize } from '../../css';

const StyledAnswer = styled.button`
  position: relative;
  -webkit-appearance: none;
  width: 100%;
  min-height: 80px;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 700;
  color: ${color.blue};
  border: 0;
  outline: 0;
  background-color: ${(props) =>
    props.isValid
      ? color.green
      : props.isInvalid
      ? color.red
      : color.lightBlue};

  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.25);
  user-select: none;

  @media (min-device-width: 768px) {
    min-height: 96px;
    border-radius: 20px;
    padding: 10px;
    box-shadow: none;
    ${fontSize.medium}
    min-height: 126px;
  }

  @media (min-device-width: 1600px) {
    min-height: 132px;
    padding: 15px;
    border-radius: 40px;
  }

  &::before,
  &::after {
    position: absolute;
    content: '';
    display: inline-block;
    width: 10px;
    top: 0;
    bottom: 0;
  }

  &::before {
    left: -10px;
  }

  &::after {
    right: 0;
  }

  @media (min-device-width: 1600px) {
    min-height: 132px;
    padding: 15px;
    border-radius: 40px;
  }

  @media (min-device-width: 768px) {
    &:hover {
      cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
      background-color: ${(props) =>
        props.disabled ? null : color.lighterBlue};
    }
  }

  @keyframes pulsate {
    from {
      background-color: ${color.lighterBlue};
    }

    to {
      background-color: ${color.lightBlue};
    }
  }

  ${({ pulsate }) =>
    pulsate &&
    css`
      background-color: color.lighterBlue;
      animation: pulsate 750ms ease-in-out infinite forwards;
    `}
`;

const Answer = ({
  id,
  text,
  handleAnswer,
  disabled,
  valid,
  pulsate,
  handleMouseOver,
  handleMouseOut
}) => (
  <StyledAnswer
    onClick={(e) => handleAnswer(id)}
    onMouseOver={(e) => handleMouseOver(id)}
    onMouseOut={(e) => handleMouseOut(id)}
    isValid={valid !== null && valid}
    isInvalid={valid !== null && !valid}
    disabled={disabled}
    pulsate={pulsate}
  >
    {text}
  </StyledAnswer>
);

Answer.propTypes = {
  id: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  handleAnswer: PropTypes.func.isRequired,
  valid: PropTypes.bool
};

Answer.defaultProps = {
  valid: null
};

export default Answer;
