import React from 'react';
import styled from 'styled-components';
import posed from 'react-pose';
import { connect } from 'react-redux';
import Button from '../Button';
import Vallineu from '../Vallineu';
import { color, fontSize } from '../../css';
import facebookIcon from './facebook.svg';
import mailIcon from './mail.svg';

const posedResultPoses = {
  init: { opacity: 0, scale: 0.75, top: 100 },
  moveIn: { opacity: 1, scale: 1, top: 0, flip: true, delay: 500 }
};

const PosedResult = styled(posed.section(posedResultPoses))`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const Box = styled.div`
  position: relative;
  width: 600px;
  max-width: 100%;
  padding: 40px 60px;
  margin: 15px;
  ${fontSize.medium}
  text-align: center;
  color: ${color.blue};
  border-radius: 30px;
  background-color: rgba(255, 255, 255, 0.8);

  @media (min-device-width: 1600px) {
    width: 864px;
    padding: 40px 80px;
    border-radius: 40px;
  }
`;

const Restart = styled.a`
  ${fontSize.small}
  color: ${color.gray};
`;

const Title = styled.h1`
  ${fontSize.extra}
  margin-bottom: 30px;
`;

const posedVallineuPoses = {
  init: { bottom: '-110vh', scale: 0.8 },
  moveIn: {
    flip: true,
    bottom: -150,
    scale: 0.86,
    delay: 1000,
    transition: {
      type: 'spring',
      stiffness: 100,
      damping: 10
    }
  },
  moveOut: {
    scale: 0.75,
    opacity: 0
  }
};

const PosedVallineu = styled(posed.div(posedVallineuPoses))`
  position: absolute;
  z-index: 2;
  bottom: -150px;
  margin-left: -160px;

  @media (min-device-width: 1600px) {
    margin-top: 290px;
    margin-left: -160px;
  }
`;

const Result = ({ correctAnswersCounter, questionCount }) => {
  let greating;
  let message;

  if ((correctAnswersCounter * 100) / questionCount >= 80) {
    greating = 'Enhorabona!';
    message = (
      <>
        Sembla que tens molts coneixements sobre l’autoprotecció. Molt bé per tu
        i pels que t’envolten! <br />
        <br />
        Si vols aprendre’n encara més o refrescar-ne alguns,t’esperem a la
        pàgina de consells de Protecció Civil.
      </>
    );
  } else if ((correctAnswersCounter * 100) / questionCount >= 50) {
    greating = 'Has aprovat!';
    message = (
      <>
        Tens alguns coneixements sobre l’autoprotecció, però podries tenir-ne
        encara més. Pensa que poden salvar-te la vida! <br />
        <br />A la pàgina de consells de Protecció Civil trobaràs un munt
        d’informació per a la teva seguretat.
      </>
    );
  } else {
    greating = 'Ui…';
    message = (
      <>
        Et falten molts coneixements sobre l’autoprotecció, que podrien
        estalviar-te ensurts, ajudar-te a protegir els qui t’envolten, a
        reaccionar en situacions de perill i fins i tot salvar-te la vida.
        <br />
        <br />
        Però no et preocupis, a la pàgina de consells de Protecció Civil
        t’expliquem tot el que necessites saber.
      </>
    );
  }

  return (
    <PosedResult initialPose="init" pose="moveIn">
      <Box>
        <Title>
          {greating}
          <br />
          {`Has encertat ${correctAnswersCounter} respostes`}
        </Title>
        <p>
          {' '}
          {message} <br />
          <br />
        </p>
        <p>
          <Button
            as="a"
            href="https://www.protecciocivil.ad/consells-autoproteccio"
            target="_blank"
            rel="noopener noreferrer"
          >
            Visita la pàgina de Protecció Civil
          </Button>
          <br />
          <br />
        </p>
        <p>
          Comparteix els resultats:
          <br />
          <br />
          <a
            href={`https://www.facebook.com/sharer/sharer.php?u=protecciocivil.ad/quiz&quote=${encodeURI(
              'Jo ja he fet el meu test en mesures d’autoprotecció. I tu, creus que saps com actuar en situacions de risc?'
            )}`}
            rel="noopener noreferrer"
          >
            <img src={facebookIcon} alt="Facebook" width={44} height={44} />
          </a>
          &nbsp;&nbsp;
          <a
            href={`mailto:?subject=${encodeURI(
              'Saps com actuar en situacions de risc?'
            )}&body=${encodeURI(
              'Posa’t a prova en aquest test: https://protecciocivil.ad/quiz'
            )}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={mailIcon} alt="Mail" width={44} height={44} />
          </a>
          <br />
          <br />
        </p>
        <p>
          <Restart href="./" onClick={() => window.location.reload()}>
            Tornar a començar el Quiz
          </Restart>
        </p>
        <PosedVallineu initialPose="init" pose="moveIn">
          <Vallineu />
        </PosedVallineu>
      </Box>
    </PosedResult>
  );
};

const mapStateToProps = ({ correctAnswersCounter, questionCount }) => ({
  correctAnswersCounter,
  questionCount
});
export default connect(mapStateToProps)(Result);
