import React from 'react';
import logo from './logo.svg';
import govern from './govern.svg';
import quiz from './quiz.svg';
import {
  HeaderWrapper,
  HeaderLogo,
  HeaderQuiz,
  HeaderGovern,
  HeaderSpacer
} from './Header.styles';

const Header = ({ showQuiz }) => (
  <HeaderWrapper>
    <HeaderLogo
      src={logo}
      alt="Protecció Civil i Gestió d'Emergències - Principat d'Andorra"
    />
    {showQuiz && (
      <HeaderQuiz src={quiz} alt="Quiz: Quant saps sobre l'autoprotecció?" />
    )}
    <HeaderSpacer />
    <HeaderGovern src={govern} alt="Govern d'Andorra" />
  </HeaderWrapper>
);

export default Header;
