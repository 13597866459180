import styled from 'styled-components/macro';

export const QuestionWrapper = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  max-width: 100%;

  @media (min-device-width: 768px) {
    align-items: center;
    padding: calc(35px + 50px);
    padding-bottom: 35px;
  }

  @media (min-device-width: 1600px) {
    padding: calc(35px + 70px);
  }
`;
