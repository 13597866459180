import styled from 'styled-components/macro';
import { color, fontSize } from '../../css';

export const ButtonBase = styled.button`
  -webkit-appearance: none;
  display: inline-block;
  padding: 10px 35px;
  ${fontSize.tiny};
  font-weight: 700;
  text-decoration: none;
  color: ${color.white};
  border-radius: 15px;
  border: 0;
  background-color: ${(props) =>
    props.color === 'blue' ? color.blue : color.red};
  outline: 0;

  @media (min-device-width: 1600px) {
    padding: 15px 42px;
  }

  &:hover {
    cursor: pointer;
    color: ${(props) => (props.color === 'blue' ? color.blue : color.white)};
    background-color: ${(props) =>
      props.color === 'blue' ? color.white : color.lightRed};
  }
`;

export const ButtonMobile = styled(ButtonBase)`
  padding: 5px 20px;

  ${fontSize.small};
  font-weight: 700;
  text-decoration: none;
  color: ${color.blue};
  border-radius: 15px;
  border: 0;
  background-color: ${color.white};
  text-align: center;
  width: 100%;
  height: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  @media (min-device-height: 641px) {
    ${fontSize.medium};
    height: 60px;
    padding: 10px 35px;
  }

  &:hover,
  &:active {
    background-color: ${color.white};
    color: ${color.blue};
  }
`;
