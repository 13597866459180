import styled from 'styled-components';

export const PreloadingScreenWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
`;
