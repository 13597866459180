import React from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as VallineuSVG } from './vallineu.svg';

const StyledVallineu = styled.div`
  width: 340px;
  height: 420px;

  @media (min-device-width: 1600px) {
    width: 440px;
    height: 543px;
  }
`;

const Vallineu = () => (
  <StyledVallineu>
    <VallineuSVG />
  </StyledVallineu>
);

export default Vallineu;
