import React, { useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import Button from '../Button';
import { color, fontSize, viewportHeight } from '../../css';
import facebookIcon from './facebook.svg';
import mailIcon from './mail.svg';

const StyledResult = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  ${viewportHeight};
  padding: 20px;
  background-color: ${color.lightBlue};

  @media (min-device-height: 641px) {
    padding: 40px;
  }

  p {
    color: ${color.blue};
    font-weight: 500;
    ${fontSize.small}

    @media (min-device-height: 700px) {
      ${fontSize.medium}

      span {
        ${fontSize.large}
      }
    }
  }
`;

const Title = styled.h1`
  ${fontSize.extra}
  margin-bottom: 30px;
  color: ${color.white};
`;

const NextOptions = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const NextOptionsItem = styled.li`
  margin-top: 10px;
`;

const StyledSharingScreen = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${color.lightBlue};
  flex-direction: column;
`;

const SharingScreenTitle = styled.h2`
  color: ${color.white};
`;

const SharingScreenLinks = styled.div`
  margin-top: 40px;
`;

const SharingScreenClose = styled.div`
  position: absolute;
  bottom: 80px;
  color: ${color.white};
`;

const Result = ({ correctAnswersCounter, questionCount }) => {
  const [state, setState] = useState({ showSharingScreen: false });
  let greating;
  let message;

  if ((correctAnswersCounter * 100) / questionCount >= 80) {
    greating = 'Enhorabona!';
    message = (
      <>
        <span>
          Sembla que tens molts coneixements sobre l’autoprotecció. Molt bé per
          tu i pels que t’envolten!
        </span>{' '}
        <br />
        <br />
        Si vols aprendre’n encara més o refrescar-ne alguns,t’esperem a la
        pàgina de consells de Protecció Civil.
      </>
    );
  } else if ((correctAnswersCounter * 100) / questionCount >= 50) {
    greating = 'Has aprovat!';
    message = (
      <>
        <span>
          Tens alguns coneixements sobre l’autoprotecció, però podries tenir-ne
          encara més. Pensa que poden salvar-te la vida!{' '}
        </span>
        <br />
        <br />A la pàgina de consells de Protecció Civil trobaràs un munt
        d’informació per a la teva seguretat.
      </>
    );
  } else {
    greating = 'Ui…';
    message = (
      <>
        <span>
          Et falten molts coneixements sobre l’autoprotecció, que podrien
          estalviar-te ensurts, ajudar-te a protegir els qui t’envolten, a
          reaccionar en situacions de perill i fins i tot salvar-te la vida.
        </span>
        <br />
        <br />
        Però no et preocupis, a la pàgina de consells de Protecció Civil
        t’expliquem tot el que necessites saber.
      </>
    );
  }

  return (
    <>
      {state.showSharingScreen && (
        <StyledSharingScreen>
          <SharingScreenTitle>Comparteix els resultats</SharingScreenTitle>
          <SharingScreenLinks>
            <a
              href={`https://www.facebook.com/sharer/sharer.php?u=protecciocivil.ad/quiz&quote=${encodeURI(
                'Jo ja he fet el meu test en mesures d’autoprotecció. I tu, creus que saps com actuar en situacions de risc?'
              )}`}
              rel="noopener noreferrer"
            >
              <img src={facebookIcon} alt="Facebook" width={88} height={88} />
            </a>
            &nbsp;&nbsp;
            <a
              href={`mailto:?subject=${encodeURI(
                'Saps com actuar en situacions de risc?'
              )}&body=${encodeURI(
                'Posa’t a prova en aquest test: https://protecciocivil.ad/quiz'
              )}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={mailIcon} alt="Mail" width={88} height={88} />
            </a>
          </SharingScreenLinks>
          <SharingScreenClose
            onClick={() => setState({ showSharingScreen: false })}
          >
            Tornar
          </SharingScreenClose>
        </StyledSharingScreen>
      )}
      <StyledResult>
        <div>
          <Title>
            {greating}
            <br />
            {`Has encertat ${correctAnswersCounter} respostes`}
          </Title>
          <p>
            {' '}
            {message} <br />
            <br />
          </p>

          <NextOptions>
            <NextOptionsItem>
              <Button
                mobile
                as="a"
                href="https://www.protecciocivil.ad/consells-autoproteccio"
                target="_blank"
                rel="noopener noreferrer"
              >
                Visita la pàgina de Protecció Civil
              </Button>
            </NextOptionsItem>
            <NextOptionsItem>
              <Button
                mobile
                onClick={() => setState({ showSharingScreen: true })}
              >
                Comparteix els resultats
              </Button>
            </NextOptionsItem>
            <NextOptionsItem>
              <Button
                mobile
                as="a"
                href="./"
                onClick={() => window.location.reload()}
              >
                Tornar a començar el Quiz
              </Button>
            </NextOptionsItem>
          </NextOptions>
        </div>
      </StyledResult>
    </>
  );
};

const mapStateToProps = ({ correctAnswersCounter, questionCount }) => ({
  correctAnswersCounter,
  questionCount
});
export default connect(mapStateToProps)(Result);
