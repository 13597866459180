import React from 'react';
import { ButtonBase, ButtonMobile } from './Button.styles';

const Button = ({ children, onClick, mobile, color, ...props }) => {
  const Button = mobile ? ButtonMobile : ButtonBase;

  return (
    <Button onClick={onClick} color={color} {...props}>
      {children}
    </Button>
  );
};

export default Button;
