import React from 'react';
import posed, { PoseGroup } from 'react-pose';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Button from '../Button';
import { color, fontSize } from '../../css';
import { nextQuestion } from '../../redux/actions';

import ok from './ok.svg';
import ko from './ko.svg';

const SuccessSign = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 8px;
`;

const explanationPoses = {
  enter: { opacity: 1, scale: 1 },
  exit: { opacity: 0, scale: 0.75, transition: { duration: 100 } }
};

const Explanation = styled(posed.div(explanationPoses))`
  display: ${(props) => (props.success !== null ? 'flex' : 'none')};
  background-color: ${(props) => (props.success ? color.green : color.red)};
  padding: 15px 20px;
  flex-direction: column;
  justify-content: space-between;
  ${fontSize.small}
  color: ${color.white};
  position: absolute;
  z-index: 100;
  top: 82px;
  height: calc(100% - 82px);

  @media (min-device-height: 641px) {
    padding: 30px 40px;
  }

  @media (min-device-width: 768px) {
    display: ${(props) => (props.success !== null ? 'block' : 'none')};
    position: relative;
    top: 0;
    width: 600px;
    max-width: 100%;
    margin-top: 20px;
    text-align: center;
    border-radius: 30px;
    height: auto;
 }

  @media (min-device-width: 1600px) {
    width: 864px;
    margin-top: 30px;
    padding: 40px 80px;
    border-radius: 40px;
 }

  p {
    font-weight: 400;
    margin-bottom: 30px;
  }
`;

const ExplanationParagraph = styled.p`
  flex: 1;
`;

const QuestionExplanation = ({ explanation, nextQuestion, success }) => (
  <PoseGroup>
    {success !== null && (
      <Explanation key="explanation" success={success}>
        <h3 style={{ color: color.blue, marginBottom: 30 }}>
          <SuccessSign src={success ? ok : ko} alt="" />
          {success ? 'Has encertat!' : 'No has encertat…'}
        </h3>
        <ExplanationParagraph>{explanation}</ExplanationParagraph>
        <Button color="blue" onClick={nextQuestion}>
          Continuar
        </Button>
      </Explanation>
    )}
  </PoseGroup>
);

export default connect(
  null,
  { nextQuestion }
)(QuestionExplanation);
