import {
  START_QUIZ,
  END_QUIZ,
  INCREMENT_COUNTER,
  NEXT_QUESTION,
  SELECT_ANSWER,
  PRESELECT_ANSWER,
  UNSELECT_ANSWER,
  MARK_SUCCESFUL_ANSWER,
  MARK_INCORRECT_ANSWER,
  UPDATE_QUESTION_COUNT,
  SET_DEVICE_TYPE
} from '../actionTypes';

const initialState = {
  hasQuizStarted: false,
  canContinueToNextQuestion: false,
  isCorrectAnswerSelected: null,
  showResult: false,
  correctAnswersCounter: 0,
  currentQuestionId: 0,
  questionCount: 10,
  selectedAnswerId: null,
  previewAnswer: null,
  deviceType: 'desktop'
};

export default (state = initialState, action) => {
  switch (action.type) {
    case START_QUIZ:
      return {
        ...state,
        hasQuizStarted: true
      };

    case END_QUIZ: {
      return {
        ...state,
        hasQuizStarted: false,
        canContinue: false,
        showResult: true
      };
    }

    case INCREMENT_COUNTER: {
      return {
        ...state,
        correctAnswersCounter: state.correctAnswersCounter + 1
      };
    }

    case NEXT_QUESTION: {
      const nextId = state.currentQuestionId + 1;
      if (nextId >= state.questionCount) {
        return {
          ...state,
          currentQuestionId: null,
          isCorrectAnswerSelected: null,
          selectedAnswerId: null,
          preselectedAnswerId: null,
          hasQuizStarted: false,
          canContinue: false,
          showResult: true
        };
      } else {
        return {
          ...state,
          currentQuestionId: state.currentQuestionId + 1,
          preselectedAnswerId: null,
          isCorrectAnswerSelected: null,
          selectedAnswerId: null
        };
      }
    }

    case SELECT_ANSWER: {
      return {
        ...state,
        selectedAnswerId: action.payload.id,
        preselectedAnswerId: action.payload.id
      };
    }

    case PRESELECT_ANSWER: {
      return {
        ...state,
        preselectedAnswerId: action.payload.id
      };
    }

    case UNSELECT_ANSWER: {
      return {
        ...state,
        preselectedAnswerId: null
      };
    }

    case MARK_SUCCESFUL_ANSWER: {
      return {
        ...state,
        isCorrectAnswerSelected: true,
        correctAnswersCounter: state.correctAnswersCounter + 1
      };
    }

    case MARK_INCORRECT_ANSWER: {
      return {
        ...state,
        isCorrectAnswerSelected: false
      };
    }

    case UPDATE_QUESTION_COUNT: {
      return {
        ...state,
        questionCount: action.payload.count
      };
    }

    case SET_DEVICE_TYPE: {
      return {
        ...state,
        deviceType: action.payload.type
      };
    }

    default:
      return state;
  }
};
