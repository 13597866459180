import React from 'react';
import styled, { css } from 'styled-components/macro';
import resultSVG from './result/scene.svg';
import posed, { PoseGroup } from 'react-pose';

export const scenes = [
  {
    0: require('./01/scene.svg'),
    1: require('./01/1.svg'),
    2: require('./01/2.svg'),
    3: require('./01/3.svg')
  },
  {
    0: require('./02/scene.svg'),
    1: require('./02/1.svg'),
    2: require('./02/2.svg'),
    3: require('./02/3.svg')
  },
  {
    0: require('./03/scene.svg'),
    1: require('./03/1.svg'),
    2: require('./03/2.svg'),
    3: require('./03/3.svg')
  },
  {
    0: require('./04/scene.svg'),
    1: require('./04/1.svg'),
    2: require('./04/2.svg'),
    3: require('./04/3.svg')
  },
  {
    0: require('./05/scene.svg'),
    1: require('./05/1.svg'),
    2: require('./05/2.svg'),
    3: require('./05/3.svg')
  },
  {
    0: require('./06/scene.svg'),
    1: require('./06/1.svg'),
    2: require('./06/2.svg'),
    3: require('./06/3.svg')
  },
  {
    0: require('./07/scene.svg'),
    1: require('./07/1.svg'),
    2: require('./07/2.svg'),
    3: require('./07/3.svg')
  },
  {
    0: require('./08/scene.svg'),
    1: require('./08/1.svg'),
    2: require('./08/2.svg'),
    3: require('./08/3.svg')
  },
  {
    0: require('./09/scene.svg'),
    1: require('./09/1.svg'),
    2: require('./09/2.svg'),
    3: require('./09/3.svg')
  },
  {
    0: require('./10/scene.svg'),
    1: require('./10/1.svg'),
    2: require('./10/2.svg'),
    3: require('./10/3.svg')
  }
];

const StyledScene = styled.figure`
  width: 100%;
  height: 100%;
`;

const fadeInOut = {
  visible: { opacity: 1, flip: false, transition: { duration: 500 } },
  invisible: {
    opacity: 0,
    flip: false,
    delay: 250,
    transition: { duration: 500 }
  },
  exit: { opacity: 0, delay: 0, transition: { duration: 500 } }
};

const BackgroundScene = styled(posed.img(fadeInOut))`
  position: absolute;
  z-index: ${({ preview, active }) => (active ? 2 : preview ? 1 : 0)};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 100vw;
  object-fit: cover;
  object-position: 30% 50%;
  will-change: opacity;

  ${({ id }) => {
    if (id === 3 || id === 4) {
      return css`
        object-position: 50% 50%;
      `;
    } else if (id === 5 || id === 8) {
      return css`
        object-position: 40% 50%;
      `;
    }
  }}

  @media (min-device-width: 768px) {
    max-height: none;
  }
`;

const Scene = ({ id, result, previewId }) => (
  <StyledScene key={id}>
    <PoseGroup flipMove={false}>
      {id && (
        <BackgroundScene
          key={scenes[id - 1][1]}
          src={scenes[id - 1][1]}
          preview={true}
          active={previewId === 0}
          pose={previewId === 0 ? 'visible' : 'invisible'}
          alt=""
          id={id}
        />
      )}
      {id && (
        <BackgroundScene
          key={scenes[id - 1][2]}
          src={scenes[id - 1][2]}
          preview={true}
          active={previewId === 1}
          pose={previewId === 1 ? 'visible' : 'invisible'}
          alt=""
          id={id}
        />
      )}
      {id && (
        <BackgroundScene
          key={scenes[id - 1][3]}
          src={scenes[id - 1][3]}
          preview={true}
          active={previewId === 2}
          pose={previewId === 2 ? 'visible' : 'invisible'}
          alt=""
          id={id}
        />
      )}
      {!result && id && (
        <BackgroundScene
          key={scenes[id - 1][0]}
          src={scenes[id - 1][0]}
          pose={'visible'}
          alt=""
          id={id}
        />
      )}
      {result && (
        <BackgroundScene
          key="result"
          src={resultSVG}
          preview={true}
          active={true}
          pose={'visible'}
          alt=""
          id={id}
        />
      )}
    </PoseGroup>
  </StyledScene>
);

export const result = resultSVG;

export default Scene;
