import React from 'react';
import styled from 'styled-components/macro';
import { connect } from 'react-redux';
import Answer from '../QuestionOption';

import {
  selectAnswer,
  markSuccesfulAnswer,
  markIncorrectAnswer,
  preselectAnswer,
  unselectAnswer,
  incrementCounter
} from '../../redux/actions';

const StyledQuestionOptions = styled.ul`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-end;
  justify-content: flex-end;
  list-style: none;
  width: 100%;

  @media (min-device-width: 768px) {
    flex-direction: row;
    justify-content: center;
  }
`;

const Option = styled.li`
  flex: 0;
  width: 100%;

  @media (min-device-width: 768px) {
    flex: 1;
    width: 0;
    padding-right: 5px;
    padding-left: 5px;
  }
`;

const mapStateToProps = ({
  selectedAnswerId,
  isCorrectAnswerSelected,
  deviceType
}) => ({
  selectedAnswerId,
  isCorrectAnswerSelected,
  deviceType
});

const mapDispatchToProps = {
  selectAnswer,
  markSuccesfulAnswer,
  markIncorrectAnswer,
  preselectAnswer,
  unselectAnswer,
  incrementCounter
};

class QuestionOptions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pulsateId: null
    };

    this.handleAnswer = this.handleAnswer.bind(this);
  }

  _showOptionStatus(id, valid) {
    const { isCorrectAnswerSelected, selectedAnswerId } = this.props;

    // Returns `true`if the option is selected (either valid or not)
    // and if the option is the valid one.
    return (
      isCorrectAnswerSelected !== null &&
      ((isCorrectAnswerSelected === true && selectedAnswerId === id) ||
        (isCorrectAnswerSelected === false && selectedAnswerId === id) ||
        (isCorrectAnswerSelected === false && valid))
    );
  }

  handleAnswer(id) {
    const {
      deviceType,
      validAnswerId,
      selectAnswer,
      markSuccesfulAnswer,
      markIncorrectAnswer
    } = this.props;

    const isMobile =
      deviceType === 'small_portrait' || deviceType === 'small_landscape';

    selectAnswer(id);
    this.setState({ pulsateId: id });

    window.setTimeout(
      () => {
        if (id === validAnswerId) {
          markSuccesfulAnswer();
        } else {
          markIncorrectAnswer();
        }

        this.setState({ pulsateId: null });
      },
      isMobile ? 1250 : 0
    );
  }

  render() {
    const {
      options,
      preselectAnswer,
      unselectAnswer,
      selectedAnswerId
    } = this.props;

    return (
      <StyledQuestionOptions>
        {options.map(({ answer, id, valid }) => {
          return (
            <Option key={id}>
              <Answer
                id={id}
                text={answer}
                handleAnswer={this.handleAnswer}
                handleMouseOver={preselectAnswer}
                handleMouseOut={unselectAnswer}
                valid={this._showOptionStatus(id, valid) ? valid : null}
                disabled={selectedAnswerId !== null}
                pulsate={id === this.state.pulsateId}
              />
            </Option>
          );
        })}
      </StyledQuestionOptions>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuestionOptions);
